@import-normalize;

html,
body,
#root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(
        to right bottom,
        #190101,
        #200406,
        #27080c,
        #2d0911,
        #350916,
        #370919,
        #390a1d,
        #3b0a20,
        #380c22,
        #350e24,
        #310f26,
        #2e1127
    );

    font-family: Montserrat, Helvetica, sans-serif;

    /* variable */
    --fadein: 1s;
    --redLabel: #570000;
    --goldLabel: #b58602;
    --whiteLabel: #d2d2d2;
    --errorColor: #e1ff00;

    --scrollBG: #3a0b20;
    --scrollBar: #570e0e;

    scrollbar-width: thin;
    scrollbar-color: var(--scrollBG) var(--scrollBar);
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--scrollBG);
}
::-webkit-scrollbar-thumb {
    background: var(--scrollBar);
    border-radius: 3px;
}

canvas {
    touch-action: none;
}

.htmlScreen iframe {
    width: 1024px;
    height: 670px;
    border: none;
    border-radius: 20px;
    background: #000000;
}

.error {
    color: var(--errorColor);
    font-size: 1.1rem;
    text-align: left;
    margin-top: 0.33rem;
    margin-bottom: -10px;
    border: 2px;
}

form {
    width: 95%;
    max-width: 400px;
    margin: 0 auto;
}

form label {
    font-size: 1rem;
    font-weight: bold;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
}

input,
select {
    width: 90%;
    padding: 0.8rem 0.8rem;
    font-size: 1.3rem;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    outline: none;
    font-family: 'Montserrat';
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 18px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

input:focus,
select:focus {
    border: 2px solid rgba(255, 255, 255, 0.3);
}

input::placeholder,
select::placeholder {
    color: #ffffff;
}

button {
    display: block;
    margin: 0.8rem 0;
    padding: 0.8rem 0.3rem;
    background: rgba(57, 0, 0, 0.844);
    border: 2px solid #ffffff6f;
    border-radius: 11px;
    width: 100%;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: 'Montserrat';
    cursor: pointer;
    color: var(--whiteLabel);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 15px 18px,
        rgba(0, 0, 0, 0.22) 0px 12px 12px;
}

.home-button {
    padding: 0.8rem 0.1rem;
    background: #390000d7;

    border: 02px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    width: 95%;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    color: var(--whiteLabel);

    /* position: relative;
	left: 33px;
	top: -22px; */

    z-index: 1002;
}

input.input-error,
select.input-error {
    border-color: var(--errorColor);
}

button:disabled {
    opacity: 0.35;
}

.container {
    /* From https://css.glass */
    background: rgba(95, 95, 95, 0.703);
    border-radius: 11px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding: 22px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15vh;
    width: clamp(10%, 333px, 85%);
    height: 533px;

    z-index: 1000;
    animation: reveal 1s;
}

.glassBG {
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px); */
    border: 1px solid rgba(255, 255, 255, 0.3);
    align-items: center;
}

.cardheaderBG {
    background: rgba(27, 26, 26, 0.942);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    /* backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px); */
    border: 2px solid rgba(98, 98, 98, 0.508);
    align-items: center;
    position: relative;
    top: -44px;
    height: 66px;
    padding: 0.8rem;
    padding-top: 1.2rem;
}

.home-ui {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 11px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 22px;

    position: absolute;
    left: 0;
    right: 0;
    top: 17%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: clamp(20%, 400px, 85%);
    height: min(60%, 300px);
    z-index: 1111;
    animation: reveal var(--fadein);
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0000007f;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1110;
    /* animation: reveal var(--fadein); */
}
.optin-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0000007f;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    /* animation: reveal var(--fadein); */
}

.header {
    background: rgba(30, 29, 29, 0.926);
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-bottom: 2px solid rgba(98, 98, 98, 0.508);

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.3rem;
    color: var(--goldLabel);
    font-weight: 600;
    font-family: Montserrat;
    font-size: large;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    animation: reveal 1s;
    z-index: 1000;
}
.hamburger {
    background: rgba(30, 29, 29, 0.926);
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(98, 98, 98, 0.508);
    border-radius: 100%;
    width: 33px;
    height: 33px;

    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.5rem;

    animation: reveal 1s;
    z-index: 999;
}
.optin-close {
    background: rgba(88, 2, 2, 0.926);
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(199, 199, 199, 0.508);
    border-radius: 100%;
    width: 17px;
    height: 17px;

    color: lightgrey;
    font-weight: 100;
    font-size: 1.1rem;

    cursor: pointer;

    position: absolute;
    top: -1.3rem;
    left: -1.3rem;
    padding: 0.5rem;
    z-index: 1112;
}
.mint-close {
    background: rgba(88, 2, 2, 0.926);
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(199, 199, 199, 0.508);
    border-radius: 100%;
    width: 10px;
    height: 10px;

    color: #d3d3d3;
    font-weight: 100;
    font-size: 0.9rem;

    cursor: pointer;

    position: absolute;
    top: -0.1rem;
    left: 1.2em;
    padding: 0.5rem;
    z-index: 1114;
}

.video-close {
    background: rgba(88, 2, 2, 0.926);
    box-shadow: 0 4px 30px rgba(29, 29, 29, 0.931);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(199, 199, 199, 0.508);
    border-radius: 100%;
    width: 10px;
    height: 10px;

    color: #d3d3d3;
    font-weight: 100;
    font-size: 0.9rem;

    cursor: pointer;

    position: absolute;
    top: 1.3rem;
    left: 1.5em;
    padding: 0.5rem;
    z-index: 2226;
}

.footer {
    background: rgba(30, 29, 29, 0.81);
    box-shadow: 0 -4px 30px rgba(29, 29, 29, 0.805);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top: 2px solid rgba(98, 98, 98, 0.508);

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    animation: reveal 1s;

    color: var(--goldLabel);
    font-weight: 600;
    font-family: Montserrat;
    font-size: medium;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    display: inline-flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.gradient-text {
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(114, 114, 114));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-text-red {
    background: -webkit-linear-gradient(#ac0202, #4a0101);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-text-gold {
    background: -webkit-linear-gradient(#f2c940, #665419);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-button-container {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: clamp(58vh, 58vh, 70vh);
    width: clamp(200px, 300px, 333px);
    height: 1rem;
    z-index: 1000;
    animation: reveal 1s;
}
.team-button-container {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: clamp(71vh, 70vh, 80vh);
    /* width: clamp(250px, 111px, 400px); */
    width: clamp(10px, 300px, 300px);
    height: 1rem;
    z-index: 1000;
    animation: reveal 1s;

    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.6rem;
}

.cookie-container {
    /* From https://css.glass */
    background: rgba(95, 95, 95, 0.703);
    border-radius: 11px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: whitesmoke;
    padding: 8px;

    width: clamp(35%, 333px, 85%);
    height: 33px;

    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.3rem;
    position: fixed;
    margin: 0 auto;
    bottom: 6rem;
    left: 0;
    right: 0;

    z-index: 1111;
    animation: reveal 1s;
}

.mint-container {
    /* From https://css.glass */
    background: rgba(95, 95, 95, 0.703);
    border-radius: 11px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #ffffff4d;
    color: whitesmoke;
    padding: 8px;

    position: absolute;
    margin: 0 auto;
    top: 5vh;
    left: 0;
    right: 0;
    width: 350px;
    z-index: 1113;
    animation: reveal 1s;
}

.video-container {
    /* From https://css.glass */
    background: rgba(95, 95, 95, 0.703);
    border-radius: 11px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #ffffff4d;
    color: whitesmoke;
    padding: 8px;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    width: 80%;

    z-index: 2223;
    animation: reveal 1s;
}

.mint-info {
    width: '222px';
    justify-content: center;
    align-items: center;
    padding: '22px';
}
.centerItPlease {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 400px;
    animation: reveal 2s;
}
.nft-mint-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-player {
    z-index: 2222;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

/* animate canvas opacity on load */
#canvas-container {
    /* background-color: #090909; */
    animation: reveal 2s;
}

@keyframes reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Media queries  */
@media (max-width: 768px) {
    .header {
        display: none;
    }
    .container {
        top: 11vh;
    }
    .home-ui {
        top: 12vh;
    }
    .mint-container {
        top: 5vh;
    }
    .video-container {
        top: 33vh;
    }
    .footer {
        gap: 0.5rem;
        /* justify-content: space-evenly; */
    }
}

@media (max-height: 800px) {
    .nft-container {
        height: 333px;
    }
}

@media (max-width: 500px) {
    .header {
        display: none;
    }
    .container {
        top: 5vh;
    }
    .home-ui {
        top: 5vh;
    }
    .mint-container {
        top: 2vh;
    }
    .video-container {
        top: 24vh;
    }

    .footer {
        /* gap: 1rem; */
        justify-content: space-evenly;
    }
}

@media (min-width: 1000px) {
    .hamburger {
        display: none;
    }
    .footer {
        gap: 5.5rem;
        font-size: larger;
    }
}

/* Change all text and icon colors in the player. */
.vjs-matrix.video-js {
    color: #f0f0f0;
}

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
    border-color: #e80000;
}

/* Change the color of various "bars". */
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
    background: #ffd900;
}
