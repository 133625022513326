.wrapper {
    z-index: 1000;
    animation: reveal 2s;
    padding: 5px;
    border-radius: 8px;
    background-color: rgb(40, 39, 39);
    border: 1px solid rgb(40, 39, 39);
    box-shadow: -14px 11px 8px rgba(0, 0, 0, 0.43);
    -webkit-box-shadow: -14px 11px 8px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: -14px 11px 8px rgba(0, 0, 0, 0.43);
    display: flex;
    flex-direction: column;
}

.sliderpicker {
    margin-top: 11px;
    padding: 11px;
}

@media (max-width: 700px) {
    .sliderpicker {
        margin-top: 5px;
        padding: 3px;
    }
    .slider-picker div[style='margin-top: 20px'] {
        margin-top: 6px !important;
    }
}

.imageInput {
    display: none;
}

.cropper-view-box {
    outline: 1px solid rgb(252, 255, 51);
    outline-color: rgba(252, 255, 51, 0.75);
}

.cropper-point {
    background-color: rgba(252, 255, 51);
}

@keyframes reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.wrapper img {
    display: block;
    height: 33vh;
}

.cropper-container {
    height: 33vh !important;
}

@media (max-width: 700px) {
    .wrapper img {
        height: 27vh;
    }
    .cropper-container {
        height: 27vh !important;
    }
}
